import React from 'react';

function Contact() {
  return (
    <section id="contact" class="contact">
      <div class="container">

        <div class="section-title">
          <h2>Contact</h2>
          {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
        </div>

        <div class="row">

          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-6 info" data-aos="fade-up">
                <i class="bx bx-map"></i>
                <h4>Address</h4>
                <p>113 Demurin Street, Ketu<br/>Lagos, Nigeria</p>
              </div>
                <div class="col-lg-6 info" data-aos="fade-up" data-aos-delay="100">
                  <i class="bx bx-phone"></i>
                  <h4>Call Us</h4>
                  <p>+234 805 7952 826<br/>+234 703 9288 232</p>
              </div>
                  <div class="col-lg-6 info" data-aos="fade-up" data-aos-delay="200">
                    <i class="bx bx-envelope"></i>
                    <h4>Email Us</h4>
                    <p>contact@kaapps.com<br/>soulwin@kaapps.com</p>
              </div>
                    <div class="col-lg-6 info" data-aos="fade-up" data-aos-delay="300">
                      <i class="bx bx-time-five"></i>
                      <h4>Working Hours</h4>
                      <p>Mon - Fri: 9AM to 5PM<br/>Saturday: 10AM to 1PM</p>
              </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <form action="forms/contact.php" method="post" role="form" class="php-email-form" data-aos="fade-up">
                      <div class="form-group">
                        <input placeholder="Your Name" type="text" name="name" class="form-control" id="name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                        <div class="validate"></div>
                      </div>
                      <div class="form-group">
                        <input placeholder="Your Email" type="email" class="form-control" name="email" id="email" data-rule="email" data-msg="Please enter a valid email" />
                        <div class="validate"></div>
                      </div>
                      <div class="form-group">
                        <input placeholder="Subject" type="text" class="form-control" name="subject" id="subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                        <div class="validate"></div>
                      </div>
                      <div class="form-group">
                        <textarea placeholder="Message" class="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us"></textarea>
                        <div class="validate"></div>
                      </div>
                      <div class="mb-3">
                        <div class="loading">Loading</div>
                        <div class="error-message"></div>
                        <div class="sent-message">Your message has been sent. Thank you!</div>
                      </div>
                      <div class="text-center"><button type="submit">Send Message</button></div>
                    </form>
                  </div>

                </div>

              </div>
    </section>
    );
  }

  export default Contact;