import { createSlice } from '@reduxjs/toolkit'
import {array_move} from '../../utils/SyncUtils'



export const initialState = {
    groupUser: "",
    currentGroup: {prospects: [], members: [], harvestLocations: [], buckets: []},
    currentGroupIndex: null,
    allGroups: []
};


const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        setGroups: (state, { payload }) => {
            return { ...state, allGroups: payload }
        },
        setCurrentGroup: (state, { payload }) => {
            let newPayload = {}
            Object.assign(newPayload, payload)
            let members = newPayload.members.slice().sort((a, b) => {
                var x = a.isAdmin
                var y = b.isAdmin
                return (x === y) ? 0 : x ? -1 : 1;
            })
            //array_move(members, 4, 0);
            let amMemberIndex = members.findIndex((element) => {
                return element.email.toLowerCase() === state.groupUser.toLowerCase();
            })
            
            if (amMemberIndex > 0)
            {
                array_move(members, amMemberIndex, 0);
            }
            newPayload["members"] = members;
            
            
            let buckets = newPayload.buckets.slice().sort((a, b) => {
                var x = a.createdOn
                var y = b.createdOn
                return (x === y) ? 0 : x ? -1 : 1;
            })
        
            let amDefaultBucket = buckets.findIndex((element) => {
                
                return element.guid.toLowerCase() === newPayload.settings.defaultBucketGuid.toLowerCase() || element.name.toLowerCase() === state.groupUser.toLowerCase();
            })

            if (amDefaultBucket > 0)
            {
                array_move(buckets, amDefaultBucket, 0);
            }
            newPayload["buckets"] = buckets;

            let harvestLocations = newPayload.harvestLocations.slice().sort((a, b) => {
                var x = a.createdOn
                var y = b.createdOn
                return (x === y) ? 0 : x ? -1 : 1;
            })

            let amDefaultHarvestLocations = harvestLocations.findIndex((element) => {
                // console.log(element.guid, newPayload.settings.defaultHarvestLocationGuid)
                return element.guid.toLowerCase() === newPayload.settings.defaultHarvestLocationGuid.toLowerCase() || element.name.toLowerCase() === state.groupUser.toLowerCase();
            })
            if (amDefaultHarvestLocations > 0)
            {
                array_move(harvestLocations, amDefaultHarvestLocations, 0);
            }
            newPayload["harvestLocations"] = harvestLocations;
            // if (newPayload.members !== null)
            // {
            //     if (newPayload.members.length > 0)
            //     {
            //         newPayload.members.sort((a, b) => {
            //             var x = a.isAdmin
            //             var y = b.isAdmin
            //             return (x === y) ? 0 : x ? -1 : 1; 
            //         })
            //     }

            // }

            return { ...state, currentGroup: newPayload }
        },
        setCurrentGroupIndex: (state, { payload }) => {
            return { ...state, currentGroupIndex: payload }
        },
        updateGroupsItem: (state, { payload }) => {
            //console.log("UpdateGroup", state.allGroups);
            state.allGroups[payload.index] = payload.item; //This Update works because am using createReducer from Redux Toolkit and Immer.
            //return {...state}
        },
        setGroupUser: (state, { payload }) => {
            return { ...state, groupUser: payload }
        }

    }

})

export const { setGroups, setCurrentGroup, setCurrentGroupIndex, updateGroupsItem, setGroupUser } = groupsSlice.actions;

// A selector
export const groupSelector = state => state.groups

// The reducer
export default groupsSlice.reducer