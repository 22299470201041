import React from 'react';

function Details() {
    return (
      <section id="details" class="details">
      <div class="container">

        <div class="row content">
          <div class="col-md-4" data-aos="fade-right">
            <img src="content/assets/img/details-1.png" class="img-fluid" alt=""/>
          </div>
          <div class="col-md-8 pt-4" data-aos="fade-up">
            <h3>Management of prospects information is not an easy task either on a personal or team or church level</h3>
            <p class="font-italic">
             How does it work?
            </p>
            <ul>
              <li><i class="icofont-check"></i> Sign up for a <strong>free</strong> personal account.</li>
              <li><i class="icofont-check"></i> Set up an outreach area either through digital map or manually.</li>
              <li><i class="icofont-check"></i> Add prospects during or after outreach.</li>
              <li><i class="icofont-check"></i> Share, track and update follow-up information on prospects .</li>
            </ul>
            {/* <p>
              Voluptas nisi in quia excepturi nihil voluptas nam et ut. Expedita omnis eum consequatur non. Sed in asperiores aut repellendus. Error quisquam ab maiores. Quibusdam sit in officia
            </p> */}
          </div>
        </div>

        <div class="row content">
          <div class="col-md-4 order-1 order-md-2" data-aos="fade-left">
            <img src="content/assets/img/details-2.png" class="img-fluid" alt=""/>
          </div>
          <div class="col-md-8 pt-5 order-2 order-md-1" data-aos="fade-up">
            <h3>Sowin makes Team Management so easy</h3>
            {/* <p class="font-italic">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
              magna aliqua.
            </p> */}
            <p>
              No matter the size of a church or ministry or team, the soul winning endeavours still boils down to the foot soldiers (The Indviduals).
              Sowin is out to help aggregate individual endeavours into group endeavours. On Sowin, a group can be the whole Church, a sub-section of a Church, a band of like-minded people. 
            </p>
            <p>
             Individuals can share their prospects with their team, squad or church (groups) and groups can also share prospects with other groups. This platform is all about Souls for Christ and making
             church growth endeavours smooth and easy.
            </p>
          </div>
        </div>

        <div class="row content">
          <div class="col-md-4" data-aos="fade-right">
            <img src="content/assets/img/details-3.png" class="img-fluid" alt=""/>
          </div>
          <div class="col-md-8 pt-5" data-aos="fade-up">
            <h3>Individual efforts aggregates to Group efforts</h3>
            <p class="font-italic">How does it work?</p>
            <ul>
              <li><i class="icofont-check"></i>A personal account holder creates a group and chooses the group size</li>
              <li><i class="icofont-check"></i>Gets other group members to sign up</li>
              <li><i class="icofont-check"></i>Adds or invites other group members</li>
              <li><i class="icofont-check"></i>Every one starts adding prospects into their individual buckets & sharing into the groups buckets</li>
            </ul>
            <p>
              <strong>Mathew 6:33</strong> - <span class="font-italic">But seek ye first the kingdom of God, and his righteousness; and all these
              things shall be added unto you.</span>
            </p>
            <p>
            <strong>Luke 15:10</strong> - <span class="font-italic">Likewise, I say to you, there is joy in the presence of the angels of God over one sinner who repents.</span>
            </p>
          </div>
        </div>

        {/* <div class="row content">
          <div class="col-md-4 order-1 order-md-2" data-aos="fade-left">
            <img src="content/assets/img/details-4.png" class="img-fluid" alt=""/>
          </div>
          <div class="col-md-8 pt-5 order-2 order-md-1" data-aos="fade-up">
            <h3>Quas et necessitatibus eaque impedit ipsum animi consequatur incidunt in</h3>
            <p class="font-italic">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
              magna aliqua.
            </p>
            <p>
              Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
              velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
              culpa qui officia deserunt mollit anim id est laborum
            </p>
            <ul>
              <li><i class="icofont-check"></i> Et praesentium laboriosam architecto nam .</li>
              <li><i class="icofont-check"></i> Eius et voluptate. Enim earum tempore aliquid. Nobis et sunt consequatur. Aut repellat in numquam velit quo dignissimos et.</li>
              <li><i class="icofont-check"></i> Facilis ut et voluptatem aperiam. Autem soluta ad fugiat.</li>
            </ul>
          </div>
        </div> */}

      </div>
    </section>

    );
  }

  export default Details;  