import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Home from './Home'
import {Callback, Login, SecuredRoute, SilentCallback, SilentRenew} from './auth/components'
// import './App.scss';
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const SignUp = React.lazy(() => import('./auth/pages/signup'));
const SignUpCompleted = React.lazy(() => import('./auth/pages/signupcompleted'));


function AppRoutes(props)
{
    return (
        <Switch>
        
          <Route path="/login">
            <Login/>
           </Route>
          <Route path="/callback">
            <Callback/>
          </Route>
          <Route path="/silent_callback">
            <SilentRenew/>
          </Route>
          <Route path="/silent_renew.html">
            <SilentRenew/>
          </Route>
          <Route path="/silent_renew">
            <SilentRenew/>
          </Route>
          
          <Route exact path="/register" name="Register Page" render={props => <SignUp {...props}/>} />
          <Route exact path="/signup" name="SignUp Page" render={props => <SignUp {...props}/>} />
          <Route exact path="/signupcompleted" name="SignUp Completed Page" render={props => <SignUpCompleted {...props}/>} />
          
          <SecuredRoute path="/dashboard">
            <DefaultLayout {...props} />
          </SecuredRoute>
          {/* <SecuredRoute path="/dashboard" name="Dashboard" render={props => <DefaultLayout {...props}/>} />  */}
          <Route path="/">
            <Home />
          </Route>
        </Switch>
    )
}



export default AppRoutes;