import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom'
import IsLoggedInContext from '../../auth/IsLoggedInContext'

function TopNav() {

  const isLoggedIn = useContext(IsLoggedInContext);
  const [isLogin, setIsLogin] = useState(false)
 
  return (
    <header id="header" class="fixed-top">
      <div class="container d-flex">

        <div class="logo mr-auto">
          {/* <h1 class="text-light"><a href="index.html">SoulWin</a></h1> */}
          <h1><a href="#topbar" class="scrollto">so<span>Win</span></a></h1>

          {/* <a href="index.html"><img src="/content/assets/img/logo.png" alt="" class="img-fluid"/></a> */}
        </div>

        <nav class="nav-menu d-none d-lg-block">
          <ul>
            <li class="active"><a href="#header">Home</a></li>
            <li><a href="#features">App Features</a></li>
            {/* <li><a href="#gallery">Gallery</a></li> */}
            {/* <li><a href="#pricing">Pricing</a></li> */}
            <li><a href="#faq">F.A.Q</a></li>
            <li><a href="#contact">Contact Us</a></li>
            {isLoggedIn ? (<li> <Link to="/dashboard">Dashboard</Link></li>) :  ""}
            <li class="get-started"><a href="#features">Get Started</a></li>
          </ul>
        </nav>

      </div>
    </header>
  );
}


export default TopNav;