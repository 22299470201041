import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import AuthReducer from './reducers/AuthReducer'
import { combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { reducer } from 'redux-oidc';
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import storage from 'redux-persist/lib/storage';
import groupsReducer from './reducers/GroupsReducer'
import myContainerReducer from './reducers/MyContainerReducer'

export const history = createBrowserHistory()
const middleware = [routerMiddleware(history)];

const rootReducer = combineReducers({
  mycontainer: myContainerReducer,
  groups: groupsReducer,
  auth: reducer,
  router: connectRouter(history)
  })

  const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['auth', 'router']
  }
  
  const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware,
  });
   
  export const persistor = persistStore(store);
// export default configureStore({
//   reducer: {
//     // counter: counterReducer,
//     // oldAuth: AuthReducer,
//     groups: groupsReducer,
//     auth: reducer,
//     router: connectRouter(history)
//   },
//   middleware,
// });

export default store;
