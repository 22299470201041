import React, { useState, useEffect, useRef } from 'react';
import userManager from "../userManager";
import { Spinner, Button } from 'reactstrap';

const onLoginButtonClick = (event) => {
    event.preventDefault();
    userManager.signinRedirect();
};

function Login() {
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(15);
    //let interval = useRef(null);

    const loadInterval = () => {
       const interval = setInterval(() => {
        if (seconds > 0) {
          
            let newSeconds = seconds - 1;
            setSeconds(newSeconds)
            console.log(seconds)
         }
         if (seconds === 0) {
             console.log("seconds is 0")
             if (minutes === 0) {
                 clearInterval(interval)
             } else {
                 setMinutes(minutes => minutes-1)
                 setSeconds(59)
             }
         } 
        }, 1000)
    }

   // const 
    userManager.signinRedirect();
    useEffect(() => {
        //console.log("This is UseEffect)")
        //userManager.signinRedirect();
        //loadInterval();
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1)
             }
             if (seconds === 0) {
                 console.log("seconds is 0")
                 if (minutes === 0) {
                     clearInterval(interval)
                 } else {
                     setMinutes(minutes-1)
                     setSeconds(59)
                 }
             } 
          }, 1000);
         return () => clearInterval(interval);
      }, [seconds]);

    return (
        <div className="d-flex justify-content-center mt-4 flex-column align-items-center">
           
            <div>
            <Spinner style={{ width: '3rem', height: '3rem' }} />
                {/* <span>Redirecting...</span> */}
            </div>

            <div className="mt-3">
                {/* <span>Click on Connect below if you are not redirected in 10 seconds</span> */}
                { minutes === 0 && seconds === 0
                    ? <React.Fragment>
                        <span>Click on Connect below if you are not redirected automatically</span>
                       <div className="d-flex justify-content-center mt-4 flex-column align-items-center">
                       <Button onClick={onLoginButtonClick} color="info">Connect</Button>
                       </div>
                        
                    </React.Fragment>
                    : <h6>Redirecting in: <strong> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</strong></h6>
                }
            </div>
 {/* <button onClick={onLoginButtonClick}>Login</button> */}
        </div>
        
    );
};

export default Login;