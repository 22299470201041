import React, { Component } from 'react';
import { HashRouter, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
//  import { renderRoutes } from 'react-router-config';
//  import routes from './routes';
import './home/css/style.css'
import './App.scss';
import './admin/css/style.css'
import AppRoutes from './AppRoutes'
import {history} from './app/store'
import { ConnectedRouter } from 'connected-react-router'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

//Main Routes
// const AppRoutes = React.lazy(() => import('./AppRoutes'));

// Containers
 const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
toast.configure();

function App()
{
  return (
    <ConnectedRouter history={history}>
       <ToastContainer />
        <React.Suspense fallback={loading()}>
          <Switch>
         
          <AppRoutes></AppRoutes>
          
            {/* <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
           */}
            {/* <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />  */}
          </Switch>
        </React.Suspense>
     </ConnectedRouter>
  );
}

// class App extends Component {

//   render() {
//     return (
//       <Router>
//           <React.Suspense fallback={loading()}>
//             <Switch>
//             <AppRoutes></AppRoutes>
//               {/* <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
//               <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
//               <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
//               <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
//               <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} /> */}
//             </Switch>
//           </React.Suspense>
//       </Router>
//     );
//   }
// }

export default App;
