import { createSlice } from '@reduxjs/toolkit'
import {array_move} from '../../utils/SyncUtils'



export const initialState = {
    user: "",
    userProfile: {},
    container: {prospects : [], members: [], buckets: [], harvestLocations: []},
};


const myContainerSlice = createSlice({
    name: 'mycontainer',
    initialState,
    reducers: {
        
        setContainer: (state, { payload }) => {
            let newPayload = {}
            Object.assign(newPayload, payload)
            let members = newPayload.members.slice().sort((a, b) => {
                var x = a.isAdmin
                var y = b.isAdmin
                return (x === y) ? 0 : x ? -1 : 1;
            })
            //array_move(members, 4, 0);
            let amMemberIndex = members.findIndex((element) => {
                return element.email.toLowerCase() === state.user.toLowerCase();
            })
            
            if (amMemberIndex > 0)
            {
                array_move(members, amMemberIndex, 0);
            }
            newPayload["members"] = members;
            
            
            let buckets = newPayload.buckets.slice().sort((a, b) => {
                var x = a.createdOn
                var y = b.createdOn
                return (x === y) ? 0 : x ? -1 : 1;
            })
        
            let amDefaultBucket = buckets.findIndex((element) => {
                // return element.name.toLowerCase() === "default" || element.name.toLowerCase() === state.user.toLowerCase();
                return element.guid.toLowerCase() === newPayload.settings.defaultBucketGuid.toLowerCase() || element.name.toLowerCase() === state.user.toLowerCase();
            })

            if (amDefaultBucket > 0)
            {
                array_move(buckets, amDefaultBucket, 0);
            }
            newPayload["buckets"] = buckets;

            let harvestLocations = newPayload.harvestLocations.slice().sort((a, b) => {
                var x = a.createdOn
                var y = b.createdOn
                return (x === y) ? 0 : x ? -1 : 1;
            })

            let amDefaultHarvestLocations = harvestLocations.findIndex((element) => {
                // return element.status.toLowerCase() === "default" || element.name.toLowerCase() === state.user.toLowerCase();
                return element.guid.toLowerCase() === newPayload.settings.defaultHarvestLocationGuid.toLowerCase() || element.name.toLowerCase() === state.user.toLowerCase();
            })
            if (amDefaultHarvestLocations > 0)
            {
                array_move(harvestLocations, amDefaultHarvestLocations, 0);
            }
            newPayload["harvestLocations"] = harvestLocations;
            // if (newPayload.members !== null)
            // {
            //     if (newPayload.members.length > 0)
            //     {
            //         newPayload.members.sort((a, b) => {
            //             var x = a.isAdmin
            //             var y = b.isAdmin
            //             return (x === y) ? 0 : x ? -1 : 1; 
            //         })
            //     }

            // }

            return { ...state, container: newPayload }
        },
    
        setUser: (state, { payload }) => {
            return { ...state, user: payload }
        },

        setUserProfile: (state, { payload }) => {
            return { ...state, userProfile: payload }
        }

    }

})

export const { setContainer, setUser, setUserProfile } = myContainerSlice.actions;

// A selector
export const myContainerSelector = state => state.mycontainer

// The reducer
export default myContainerSlice.reducer