import React, {useEffect} from 'react';
import { Link, useHistory  } from 'react-router-dom';
import userManager from "../../auth/userManager";
import { useSelector } from "react-redux";
import {  Button} from 'reactstrap';

function LandingPage() {
  const stateAuth = useSelector(state => state.auth);
  const reduxUser = stateAuth.user;

  const history = useHistory();

  useEffect(() => {
    var mobileButton = document.getElementById('btnMobileNav')
    if (mobileButton !== null && mobileButton !== undefined)
    {
      mobileButton.classList.add("sync-visible");
    }
  }, [])

  const onLoginButtonClick = (event) => {
    event.preventDefault();
    userManager.getUser().then(function (user) {
      if (user) {
          console.log("User logged in", user.profile);
         // log("User logged in", user.profile)
         if (user.expired)
         {
           console.log("user is expired");
           userManager.signinRedirect();
         }
         else
         {
           if (reduxUser === null || reduxUser === undefined)
           {
            console.log("Not expired but not in scope")
            userManager.signinRedirect();
           }
           else
           {
            console.log("Not expired")
            history.push("/dashboard")
           }
         
         }
        
      }
      else {
          console.log("User not logged in");
          //log("User not logged in")
          userManager.signinRedirect();
      }
  });
    
};
  
  return (
    <section id="hero" class="d-flex align-items-center">

      <div class="container">
        <div class="row mt-2">
          <div class="col-lg-12 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-2 pt-lg-0 order-2 order-lg-1" data-aos="fade-up">
            <div >
              <h1 class="d-flex justify-content-center align-items-center align-content-center">sowin.io "Manage all your prospects"</h1>
              <h2>soWin helps in personal and groups (Church, Ministry or Team) outreach endeavours by managing prospects, locations and teams effectively</h2>
              <div class="d-flex justify-content-center align-items-center align-content-center">
                <a href="javascript.void()" onClick={onLoginButtonClick}  class="login-btn"><i class="fas fa-sign-in-alt"></i> Login</a>
                {/* <Button color="success" size="sm" onClick={onLoginButtonClick}>Export Prospects</Button> */}
            {/* <a href="/register" class="signup-btn"><i class="bx bx-add-to-queue"></i> Sign up</a> */}
                {/* <Link to="/login" class="login-btn">
                  <i class="fas fa-sign-in-alt"></i> Login
                                </Link> */}
                <Link to="/register" class="signup-btn">
                  <i class="bx bx-add-to-queue"></i> Sign up
                                </Link>
              </div>

            </div>
          </div>
          {/* <div class="col-lg-6 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img" data-aos="fade-up">
          <img src="content/assets/img/hero-img.png" class="img-fluid" alt=""/>
        </div> */}
        </div>
      </div>

    </section>
  );
}


export default LandingPage;