import React, {useState} from 'react';
 import {TopNav, LandingPage, Features, Details, Gallery, Testimonials, Pricing, Contact, Faqs, HomeFooter} from './home/components'
//  import './home/css/style.css'
import userManager from "./auth/userManager";
import IsLoggedInContext from './auth/IsLoggedInContext'


function Home(props) {

    // const history = useHistory();
    const [isLogin, setIsLogin] = useState(false)
    userManager.getUser().then(function (user) {
        if (user) {
           setIsLogin(true)
        }
        else {
           setIsLogin(false)
        }
    });
  

    return (
        <div className="sowin-home">
            <IsLoggedInContext.Provider value={isLogin}>
            <TopNav {...props}></TopNav>
            <LandingPage {...props}></LandingPage>
            <Features {...props} ></Features>
<Details></Details>
            {/* <Gallery></Gallery> */}
            {/* <Testimonials></Testimonials> */}
            {/* <Pricing></Pricing> */}
            <Faqs></Faqs>
          <Contact></Contact>
          <HomeFooter></HomeFooter>
          </IsLoggedInContext.Provider>
        </div>
    );
}

export default Home;  