import React from 'react';
import Oidc from 'oidc-client'
import { useHistory } from "react-router-dom";
import {connect} from "react-redux"
import { CallbackComponent } from "redux-oidc";
import userManager from "../userManager";
//import {} from './app/'

// function Callback(props) {
//     let history = useHistory();

//     new Oidc.UserManager({response_mode:"query"}).signinRedirectCallback().then(function(user) {
//         //window.location = "index.html";
//         console.log("Charles", user)
//         history.push('/dashboard')
//     }).catch(function(e) {
//         console.error(e);
//     });

//   return (
//       <div>
//           Callback Page
//       </div>
//   )
//   }

const Callback = () => {
    const history = useHistory();
  
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={() => {
          console.log("Success");
          history.push("/dashboard");
        }}
        errorCallback={error => {
          history.push("/dashboard");
          console.error(error);
        }}
      >
        <div>Redirecting...</div>
      </CallbackComponent>
    );
  };

  export default Callback;